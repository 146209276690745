// Skiplink

$(document).ready(function() {
	$('a[href^="#"]').click(function(e) {

		e.preventDefault();

		var target = this.hash;
		var $target = $(target);

		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
		}, 750, 'easeInOutExpo', function () {
			window.location.hash = target;
		});
	});
});

// Set home

function setSectionHeight() {

	$('#home').height($(window).height());
	
}

setSectionHeight();

$(window).resize(function(){

	setSectionHeight()

});

$('.claim').typeIt();

// Home Slider

$('.homeslider').slick({
	autoplay: true,
	autoplaySpeed: 10000,
	fade: true,
	arrows: false,
	dots: false,
	infinite: true,
	pauseOnHover: false,
	speed: 2000
});

// Navigation

$('.open').click(function(){
	$('nav ul').prepend('<li><a class="close" href="#"><i class="fa fa-times"></i></a></li>');
	$('nav').css({
		'display' : 'table'
	});
	$('nav').animate({
		'opacity' : 1,
		'height' : $(window).height()
	}, 1500, 'easeOutExpo',function(){
		$('nav li').each(function(index) {
			$(this).delay(100*index).animate({
				'opacity' : 1
			});
		});
	})
});

$(document).on('click', '.close', function(){
	$('nav').animate({
		'opacity' : 0
	}, 500, 'linear', function(){
		$(this).css({
			'display' : 'none',
			'height' : '0px'
		});
		$('nav li').css({
			'opacity' : 0
		});
		$('nav .close').remove()
	})
});

$(document).ready(function(){
	if(Foundation.MediaQuery.current == 'small') {
		$(document).on('click', 'nav a', function(){
			$('nav').animate({
				'opacity' : 0
			}, 500, 'linear', function(){
				$(this).css({
					'display' : 'none',
					'height' : '0px'
				});
				$('nav li').css({
					'opacity' : 0
				});
				$('nav .close').remove()
			})
		});
	}
	
});

// Inzahlen

function setCircles() {
	$('.icon').height($('.icon').width());
	$('.icon').each(function(){
		$(this).find('img').each(function(){
			$(this).position({
				my: 'center center',
				at: 'center center',
				of: $(this).parent('.icon')
			})
		})
	})
}

setCircles();

$(window).resize(function(){
	setCircles()
});

// Google Maps

function initMap() {

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 10,
		center: {lat: 47.357338, lng: 8.408734}
	});

	setMarkers(map);
}

var niederlassungen = [
	['Lindenberg Partner AG, Wohlen', 47.349096, 8.259015, 1],
	['Lindenberg Partner AG, Zürich', 47.373545, 8.530509, 2]
];

function setMarkers(map) {
	var marker;
	var image = {
		url: 'assets/img/mapmarker.png',
		// This marker is 100 pixels wide by 100 pixels high.
		size: new google.maps.Size(100, 100),
		// The origin for this image is (0, 0).
		origin: new google.maps.Point(0, 0),
		// The anchor for this image is the base of the flagpole at (0, 32).
		anchor: new google.maps.Point(100, 100)
	};
	var myIcon = new google.maps.MarkerImage('assets/img/mapmarker.png', null, null, null, new google.maps.Size(50,50));
	for (var i = 0; i < niederlassungen.length; i++) {
		var beach = niederlassungen[i];
		marker = new google.maps.Marker({
			position: {lat: beach[1], lng: beach[2]},
			map: map,
			icon: myIcon,
			title: beach[0],
			zIndex: beach[3]
		});
	}
}

// E-Mailer

$('form#contactform').on('submit', function(){

	var url = $(this).attr('action');

	var method = $(this).attr('method');

	var data = {};

	$(this).find('[name]').each(function(index, value){
		var name = $(this).attr('name');
		var value = $(this).val();

		data[name] = value;

	});

	$.ajax({
		url: url,
		type: method,
		data: data,
		success: function(response) {

			//console.log(response);

			$('.success').slideDown().delay(5000).slideUp();
			$('input[name=name], input[name=telmail]').val('');

		},
		error: function() {

			$('.alert').slideDown().delay(5000).slideUp();

		}

	});

	return false;

});

// Verschwindibus


$(document).ready(function(){
	if(Foundation.MediaQuery.atLeast('large')) {
		$('.core-businesses').hover(
			function(){
				$('.core-businesses').not(this).stop().animate({opacity: 0.25});
			},
			function(){
				$('.core-businesses').not(this).stop().animate({opacity: 1});
			}
		)
	}
});